import { Button, Form, Radio, Spin } from "antd";
import Help from "../../../Help";
import More from "../../../More";
import React, { useEffect, useState } from "react";

import "./RadioGroup.scss";

export default function RadioGroupElement({active, disabled, element, isChild = false, loadingSubmission, onChange, submission}) {
    const [value, setValue] = useState(submission?.data?.find((d) => d.key === element.webform_key)?.value);

    useEffect(() => {
        if (!loadingSubmission)
            setValue(submission?.data?.find((d) => d.key === element.webform_key)?.value);

    }, [loadingSubmission, submission?.data?.find((d) => d.key === element.webform_key)?.value]);

    const data = submission?.data?.find((d) => d.key === element.webform_key) ?? null;
    const isCalculation = (!!element.is_multilevel && !!submission && !!submission?.child_organisations?.length) || !!element.is_autocalculated;
    const isOverwritten = !!(data?.is_overwritten);
    const multiset = !!(submission?.parent_organisation);
    const readonly = Object.hasOwn(element, 'readonly') ? !!element.readonly : false;

    return (
        <>
            {loadingSubmission ? (<Spin/>) : (
                <Form>
                    <div className="qs-area">
                        {!isChild && (
                            <Help active={active} help={element.help} required={element.required} title={element.title}/>
                        )}
                        <Form.Item>
                            <Radio.Group 
                                disabled={(multiset && data?.is_default_value) || readonly || (submission?.completed && !isChild) || disabled}
                                onChange={(v) => {setValue(v.target.value); onChange(submission.organisation_id, element.webform_key, v.target.value);}}
                                options={element.options?.map((opt) => ({ label: opt.value, value: opt.key }))}
                                value={value}/>
                        </Form.Item>

                        {!readonly && isCalculation && isOverwritten && (
                            <Button>Waarde berekenen</Button>
                        )}

                        {!isChild && <More more={element.more} moreTitle={element.more_title} />}
                    </div>
                </Form>
            )}
        </>
    );
}