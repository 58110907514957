import React, { useEffect, useState } from "react";
import { Collapse, Modal, Popover, Spin } from "antd";

import BenchmarkMap from "../../../../../../assets/images/svg/benchmarkMap";
import PieChartOutlined from "@ant-design/icons/lib/icons/PieChartOutlined";
import InfoCircleOutlined from "@ant-design/icons/lib/icons/InfoCircleOutlined";
import BranchesOutlined from "@ant-design/icons/lib/icons/BranchesOutlined";

import { StarFilled, StarOutlined } from "@ant-design/icons";

export default function GridOrganizationRow({
  t,
  item,
  index,
  org,
  number,
  isLoader,
  openModal,
  reportData,
  indicatorValue,
  settingsBenchmark,
  openDrilldownModal,
  openDrilldownOrganisationModal,
  drillDownData,
  formatIndicatorValue,
  specialOrganisations,
  organizationData,
  checkedList,
  activeOrganization,
  favoriteIndicators,
  comparisonGroups,
  onToggleFavorite,
}) {
  const setShowMap = () => settingsBenchmark?.maps?.show_maps_view === "yes";

  if (isLoader)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "10px 0",
        }}
      >
        <Spin size={"large"} />
      </div>
    );

  let showIndividualScores = settingsBenchmark?.show_individual_scores === "yes";
  const isFavorite = !!favoriteIndicators?.find((fav) => fav.indicator === item.webform_indicator);

  return (
    <div className={"report__content-block"} key={item.title + number}>
      <div className={"tab__title"}>
        <div className={"tab__title-text"}>
          {item.title} {org?.raw_date}
        </div>
        <div className={"tab__title-content"}>
          <div
            className={"map"}
            onClick={() =>
              openModal(
                item,
                reportData,
                index,
                "map",
                org?.indicator_value,
                item.webform_indicator,
                indicatorValue
              )
            }
          >
            {setShowMap() && (
              <>
                <BenchmarkMap className={"map__img"} />
                {t("card")}
              </>
            )}
          </div>

          <div
            className={"chart"}
            onClick={() =>
              openModal(
                item,
                reportData,
                number,
                "charts",
                org?.indicator_value,
                item.webform_indicator
              )
            }
          >
            <PieChartOutlined />
            {t("chart")}
          </div>

          {!!item.is_multilevel && settingsBenchmark?.show_drilldown_in_own_hierarchy === "yes" && !!comparisonGroups.length &&
            (
              <div
                className={"chart"}
                onClick={() => openDrilldownModal(item.webform_indicator)}
              >
                <BranchesOutlined />
                {t("drilldown")}
              </div>
            )}

          {!!item.is_multilevel &&
            settingsBenchmark?.show_drilldown_in_own_hierarchy === "yes" && (
              <div
                className={"chart"}
                onClick={() => openDrilldownOrganisationModal(item.webform_indicator)}
              >
                <BranchesOutlined />
                {t("drilldown_organisation")}
              </div>
            )}

          {!!item.is_benchmarking && (
            <div className={"info"}>
              {item.webform_indicator}
              <Popover content={item.description} title={t("description")}>
                <InfoCircleOutlined />
              </Popover>
            </div>
          )}

          {!!item.is_benchmarking && (
            <FavoriteButton
              title={item.title}
              indicator={item.webform_indicator}
              checked={isFavorite}
              toggleFavorite={onToggleFavorite}
            />
          )}
        </div>
      </div>

      {showIndividualScores && (<ActiveOrganisation item={item} indicatorValue={indicatorValue} />)}

      {specialOrganisations?.map((item) => (
          <SpecialOrganisations
            index={number}
            item={item}
            organizationData={organizationData}
          />
        )
      )}

        
      <ComparisonGroupColumn item={item} drillDownData={drillDownData} />

      <ExtraColumns
        item={item}
        number={number}
        reportData={reportData}
        checkedList={checkedList}
        extra_columns={settingsBenchmark?.extra_columns}
      />
    </div>
  );
}

function IndicatorColumnRaw({
  value,
  decimal_places = 2,
  numeric_decimal = ",",
  numeric_thousand = ".",
}) {
  return (
    <div className={"report__content"}>
      {formatIndicatorValue(
        value || "-",
        decimal_places,
        numeric_decimal,
        numeric_thousand
      )}
    </div>
  );
}

function IndicatorColumn({ value, element }) {
  return (
    <IndicatorColumnRaw
      value={value}
      decimal_places={element.decimal_places}
      numeric_decimal={element.numeric_decimal}
      numeric_thousand={element.numeric_thousand}
    />
  );
}

function ComparisonGroupColumn({ drillDownData, item }) {
  if (!!!drillDownData?.values?.current) return null;

  return (
    <IndicatorColumn
      value={drillDownData?.values?.current.organizationsResult}
      element={item}
    />
  );
}

function FavoriteButton({ title, indicator, checked, toggleFavorite }) {
  let className = "favorite";
  if (checked) className += " checked";

  let icon = checked ? <StarFilled /> : <StarOutlined />;

  return (<div className={className} onClick={() => toggleFavorite(title, indicator)}>{icon}</div>);
}

function SpecialOrganisations({ index, item, organizationData }) {
  let orgData = organizationData?.find((val) => val.organization === item.id);
  let indicator = orgData?.items.find((i) => i.indicator == item.webform_indicator);

  return (<IndicatorColumn value={indicator?.result?.[index]?.indicator_value} element={item} />);
}

function ExtraColumns({
  item,
  number,
  reportData,
  checkedList,
  extra_columns,
}) {
  return checkedList?.map((obj) => {
    for (let key in extra_columns) {
      if (extra_columns?.[key] === obj) {
        let val = reportData?.[`${key}`]?.[0]?.items?.find(
          (i) => i.indicator == item.webform_indicator
        )?.result?.[number]?.indicator_value;

        return (<IndicatorColumn value={val} element={item} />);
      }
    }
  });
}

function ActiveOrganisation({ item, indicatorValue }) {
  return (<IndicatorColumn value={indicatorValue} element={item} />);
}

function formatIndicatorValue(
  value,
  decimal_places = 2,
  numeric_decimal = ",",
  numeric_thousand = "."
) {
  if (value === "-")
    return "-";

  let number = value;
  let decimals = decimal_places ?? 2;
  let decpoint = numeric_decimal ?? ",";
  let thousand = numeric_thousand ?? ".";

  let n = Math.abs(number).toFixed(decimals).split(".");
  n[0] = n[0]
    .split("")
    .reverse()
    .map((c, i, a) => (i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c))
    .reverse()
    .join("");

  return (Math.sign(number) < 0 ? "-" : "") + n.join(decpoint);
}
