import { connect } from 'react-redux';
import ReportBenchmark from './ReportBenchmark';
import actions from "../../store/actions";

const mapStateToProps = (state) => ({
  user: state.user,
  settingsBenchmark: state.benchmark.benchmarkSettings,
  isFetching: state.benchmark.isFetching,
  forms: state.benchmark.benchmarkForms,
  comparisonGroups: state.benchmark.comparisonGroups || [],
});

const mapDispatchToProps = (dispatch) => ({
  getBenchmarkOrgDataForCategory: (params) => dispatch(actions.benchmark.getBenchmarkOrgDataForCategory(params)),
  getBenchmarkPeriods: (params) => dispatch(actions.benchmark.getBenchmarkPeriods(params)),
  getBenchmarkDataForCategory: (params) => dispatch(actions.benchmark.getBenchmarkDataForCategory(params)),
  getBenchmarkComparisonGroups: () => dispatch(actions.benchmark.getBenchmarkComparisonGroups()),
  getBenchmarkForms: () => dispatch(actions.benchmark.getBenchmarkForms()),
  getBenchmarkSettings: () => dispatch(actions.benchmark.getBenchmarkSettings()),
  createNewBenchmarkView: (params, id) => dispatch(actions.benchmark.createNewBenchmarkView(params, id)),
  getBenchmarkViewList: (id) => dispatch(actions.benchmark.getBenchmarkViewList(id)),
  getBenchmarkSpecialView: (id) => dispatch(actions.benchmark.getBenchmarkSpecialView(id)),
  getBenchmarkDataForIndicator: (params) => dispatch(actions.benchmark.getBenchmarkDataForIndicator(params)),
  getBenchmarkDrillDown: (params) => dispatch(actions.benchmark.getBenchmarkDrillDown(params)),
  getBenchmarkFavoriteViews: (params) => dispatch(actions.benchmark.getBenchmarkFavoriteViews(params)),
  setBenchmarkFavoriteViews: (params) => dispatch(actions.benchmark.setBenchmarkFavoriteViews(params)),
  getBenchmarkFavorites: (params) => dispatch(actions.benchmark.getBenchmarkFavorites(params)),
  addBenchmarkFavorite: (params) => dispatch(actions.benchmark.addBenchmarkFavorite(params)),
  removeBenchmarkFavorite: (params) => dispatch(actions.benchmark.removeBenchmarkFavorite(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportBenchmark);
