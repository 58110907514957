import React, { useEffect, useState } from "react";
import { Collapse, Modal, Spin } from "antd";

import { NoRecords } from "../../../../components";
import ModalCharts from "../../modal/ModalCharts";
import ModalDrillDownGroups from "../../modal/ModalDrillDownGroups";
import ModalDrillDownOrganization from "../../modal/ModalDrillDownOrganization";
import GridOrganizationRow from "./components/GridOrganizationRow";

const { Panel } = Collapse;

const ReportContent = ({
  organizationData,
  elementsData,
  reportData,
  favoriteIndicators,
  settingsBenchmark,
  startDate,
  endDate,
  periodType,
  checkedList,
  t,
  mapFile,
  optionList,
  defaultActiveKey,
  comparisonGroups,
  specialOrganisations,
  activeOrganization,
  drillDownGroups,
  getBenchmarkDrillDown,
  isDrillDown,

  onToggleFavorite,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalDrilldownGroupsVisible, setModalDrilldownGroupsVisible] = useState(false);
  const [modalDrilldownChildOrganizationVisible, setModalDrilldownChildOrganizationVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalValue, setModalValue] = useState(null);
  const [modalCharts, setModalCharts] = useState(null);
  const [modalIndicator, setModalIndicator] = useState(null);
  const [indexCharts, setIndexCharts] = useState(null);
  const [defaultTabKey, setDefaultTabKey] = useState(null);
  const [collapseActiveKey, setCollapseActiveKey] = useState([]);
  const [organisationData, setOrganisationData] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState(null);
  const [descriptionText, setDescriptionText] = useState(null);
  const [drillDownData, setDrillDownData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [mapOrgData, setMapOrgData] = useState(null);

  const formatIndicatorValue = (value, tableItem) => {
    if (value === "-") {
      return "-";
    }

    let number = value;
    let decimals = tableItem.decimal_places ?? 2;
    let decpoint = tableItem.numeric_decimal ?? ",";
    let thousand = tableItem.numeric_thousand ?? ".";

    let n = Math.abs(number).toFixed(decimals).split(".");
    n[0] = n[0]
      .split("")
      .reverse()
      .map((c, i, a) =>
        i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c
      )
      .reverse()
      .join("");

    return (Math.sign(number) < 0 ? "-" : "") + n.join(decpoint);
  };

  useEffect(() => {
    setCollapseActiveKey(defaultActiveKey);
  }, [defaultActiveKey]);

  const closeModal = () => {
    setModalVisible(false);
  };

  const closeDrilldownGroupsModal = () => setModalDrilldownGroupsVisible(false);

  const closeDrilldownChildOrganizationModal = () => setModalDrilldownChildOrganizationVisible(false);

  const openModal = (
    value,
    reportData,
    number,
    tabKey = "charts",
    organisationData = null,
    indicator,
    mapOrgDataValue = false
  ) => {
    const currentWebform = elementsData?.find(
      (item) => item.webform_indicator === indicator
    );

    setModalValue(value);
    setModalCharts(reportData);
    setIndexCharts(number);
    setModalTitle(value?.title);
    setModalVisible(true);
    setDefaultTabKey(tabKey);
    setOrganisationData(organisationData);
    setModalIndicator(indicator);
    setDecimalPlaces(currentWebform?.decimal_places);
    setDescriptionText(value?.description);

    if (mapOrgDataValue) {
      setMapOrgData(mapOrgDataValue);
    }
  };

  const openDrilldownModal = (indicator) => {
    setModalIndicator(indicator);
    setModalDrilldownGroupsVisible(true);
  };

  const openDrilldownOrganisationModal = (indicator) => {
    setModalIndicator(indicator);
    setModalDrilldownChildOrganizationVisible(true);
  };

  const setShowMap = () => settingsBenchmark?.maps?.show_maps_view === "yes";

  const handlerOpenCollapse = async (value, open) => {
    setIsOpen((prev) => !prev);

    if (!collapseActiveKey?.length) {
      const activeKeys = [];
      activeKeys.push(value.element_indicator);
      setCollapseActiveKey(activeKeys);
    } else {
      const activeKeys = [...collapseActiveKey];
      const hasActiveKey = !!activeKeys.find(
        (item) => item === value.element_indicator
      );

      if (hasActiveKey) {
        const newActiveKeys = activeKeys.filter(
          (item) => item !== value.element_indicator
        );
        setCollapseActiveKey(newActiveKeys);
      } else {
        activeKeys.push(value.element_indicator);
        setCollapseActiveKey(activeKeys);
      }
    }

    if (open && isDrillDown && drillDownGroups) {
      setIsLoader(true);

      await getBenchmarkDrillDown({
        batch: true,
        indicator: value?.webform_indicator,
        groups:  drillDownGroups,
        organization: activeOrganization?.id,
        period_start: startDate,
        period_end: endDate, 
        calculation_types: ["current"],
      })
        .then((response) => {
          const { data } = response;
          setDrillDownData(data);
        })
        .catch((e) => console.error("error ", e))
        .finally(() => setIsLoader(false));
    }
  };

  function GridRow({ item, index }) {
    if (!!!item.is_benchmarking) return null;

    const orgData = organizationData?.[0]?.items?.find(
      (i) => i.indicator == item.webform_indicator
    );

    function GridRow({ org, number }) {
      return (
        <GridOrganizationRow
          key={item.title + number}
          t={t}
          item={item}
          index={index}
          org={org}
          number={number}
          isLoader={isLoader}

          checkedList={checkedList}
          activeOrganization={activeOrganization}
          settingsBenchmark={settingsBenchmark}

          reportData={reportData}
          drillDownData={drillDownData}
          specialOrganisations={specialOrganisations}
          organizationData={organizationData}
          favoriteIndicators={favoriteIndicators}
          comparisonGroups={comparisonGroups}
          
          openModal={openModal}
          openDrilldownModal={openDrilldownModal}
          openDrilldownOrganisationModal={openDrilldownOrganisationModal}
          onToggleFavorite={onToggleFavorite}
        />
      );
    }

    return (
      <Collapse
        key={item.webform_indicator}
        activeKey={collapseActiveKey}
        onChange={() => handlerOpenCollapse(item, !isOpen)}
      >
        <Panel
          key={item.element_indicator}
          header={item.title}
          className={"report__item"}
        >
          {orgData?.result?.length ? (
            orgData?.result?.map((org, number) => (
              <GridRow org={org} number={number} />
            ))
          ) : (
            <GridRow />
          )}
        </Panel>
      </Collapse>
    );
  }

  function Grid() {
    if (!!elementsData === false) return <NoRecords />;

    return elementsData?.map((item, index) => (
      <GridRow key={item.webform_indicator} item={item} index={index} />
    ));
  }

  return (
    <>
      <Modal
        width={"1024px"}
        footer={null}
        open={modalVisible}
        onCancel={closeModal}
        title={modalTitle}
        className={"report__modal"}
      >
        <ModalCharts
          modalVisible={modalVisible}
          defaultTabKey={defaultTabKey}
          optionList={optionList}
          modalValue={modalValue}
          modalCharts={modalCharts}
          indexCharts={indexCharts}
          settingsBenchmark={settingsBenchmark}
          showMap={setShowMap()}
          startDate={startDate}
          endDate={endDate}
          periodType={periodType}
          t={t}
          mapFile={mapFile}
          organisationData={organisationData}
          comparisonGroups={comparisonGroups}
          indicator={modalIndicator}
          activeOrganization={activeOrganization}
          drillDownGroups={drillDownGroups}
          decimalPlaces={decimalPlaces}
          descriptionText={descriptionText}
          mapOrgData={mapOrgData}
        />
      </Modal>
      <Modal
        key={modalIndicator + 'group'}
        width={"600px"}
        footer={null}
        open={modalDrilldownGroupsVisible}
        onCancel={closeDrilldownGroupsModal}
        title={t("drilldown")}
        className={"report__modal"}
      >
        <ModalDrillDownGroups
          indicator={modalIndicator}
          groups={drillDownGroups}
          periodStart={startDate}
          periodEnd={endDate}
          calculationTypes={checkedList}
          t={t}
        />
      </Modal>
      <Modal
        key={modalIndicator + 'org'}
        width={"600px"}
        footer={null}
        open={modalDrilldownChildOrganizationVisible}
        onCancel={closeDrilldownChildOrganizationModal}
        title={t("drilldown")}
        className={"report__modal"}
      >
        <ModalDrillDownOrganization
          indicator={modalIndicator}
          organization={activeOrganization}
          periodStart={startDate}
          periodEnd={endDate}
          calculationTypes={checkedList}
          t={t}
        />
      </Modal>
      <Grid />
    </>
  );
};

export default ReportContent;
