import React, { useEffect, useState } from "react";
import { Collapse, Modal, Popover, Spin } from "antd";

import {
  PieChartOutlined,
  InfoCircleOutlined,
  DeleteOutlined,
  BranchesOutlined,
} from "@ant-design/icons/lib/icons";
import BenchmarkMap from "../../../../assets/images/svg/benchmarkMap";
import { NoRecords } from "../../../../components";
import ModalCharts from "../../modal/ModalCharts";
import ModalDrillDown from "../../modal/ModalDrillDown";

const { Panel } = Collapse;

export default function FavoritesGrid({
  indicator,
  title,
  index,
  // favoritesData,
  t,
  user,
  description,
  active_organisation,
  period_start,
  period_end,
  calculation_types,
  category,
  comparison_groups,
  individual_organisations,
  getBenchmarkValuesForIndicator,
  handleDeleteFavorite
}) {
  const [isLoader, setIsLoader] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [item, setItem] = useState({});

  const formatIndicatorValue = (value, tableItem) => {
    if (value === "-" || value === -1) {
      return "-";
    }

    let number = value;
    let decimals = tableItem.decimal_places ?? 2;
    let decpoint = tableItem.numeric_decimal ?? ",";
    let thousand = tableItem.numeric_thousand ?? ".";

    let n = Math.abs(number).toFixed(decimals).split(".");
    n[0] = n[0]
      .split("")
      .reverse()
      .map((c, i, a) =>
        i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c
      )
      .reverse()
      .join("");

    return (Math.sign(number) < 0 ? "-" : "") + n.join(decpoint);
  };

  useEffect(() => {
    LoadData();
  }, [active_organisation, period_start]);

  useEffect(() => {
    LoadData();
  }, []);

  function LoadData(){
    setIsLoader(true);

    getBenchmarkValuesForIndicator({
      indicator,
      period_start,
      period_end,
      category,
      active_organisation,
      comparison_groups,
      individual_organisations,
      calculation_types
    }).then((result) => {
      setItem(result.values[0]);
      setIsLoader(false);
    });
  }

  function DeleteFavoriteButton({ title, indicator, deleteFavorite }) {
    return (
      <DeleteOutlined  style={{color:'red'}} onClick={() => deleteFavorite(title, indicator)} />
    );
  }

  const activeKey = isCollapsed ? "" : indicator;

  return (
    <Collapse
      key={indicator}
      activeKey={activeKey}
      onChange={() => setIsCollapsed(!isCollapsed)}
    >
      <Panel key={indicator} header={title} className={"report__item"}>
        {isLoader ? (
          <Loader />
        ) : (
          
          <div className={"report__content-block"} 
               key={index}>
            <div className={"tab__title"}>
              <div className={"tab__title-text"}>{title}</div>
              <div className={"tab__title-content"}>
                <div className={"info"}>
                  {indicator}
                  <Popover content={description} title={t("description")}>
                    <InfoCircleOutlined />
                  </Popover>
                  <DeleteFavoriteButton title={title} indicator={indicator} deleteFavorite={handleDeleteFavorite} />
                </div>
              </div>
            </div>

            {item?.values?.map((value, index) => (
              <div className={"report__content"} key={"column_" + index}>
                {formatIndicatorValue(value || "-", item)}
              </div>
            ))}
          </div>
        )}
      </Panel>
    </Collapse>
  );
}

function Loader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "10px 0",
      }}
    >
      <Spin size={"large"} />
    </div>
  );
}
