import { Button, Upload } from "antd";
import Help from "../../../Help";
import More from "../../../More";
import React from "react";
import { UploadOutlined } from "@ant-design/icons"; 

export default function FileElement({active, disabled, element, isChild = false, submission}) {
    const data = submission?.data?.find((d) => d.key === element.webform_key) ?? null;
    const multiset = !!(submission?.parent_organisation);
    const submittable = !!submission && submission.progress.basic.filled === submission.progress.basic.count_required;

    return (
        <>
            <div className="qs-area">
                {!isChild && (
                    <Help active={active} help={element.help} required={element.required} title={element.title}/>
                )}

                <Upload>
                    <Button>
                        <UploadOutlined disabled={(multiset && data?.is_default_value) || submittable || (submission?.completed && !isChild) || disabled}/>
                        {" "}
                        Click to Upload
                    </Button>
                </Upload>

                {!isChild && <More more={element.more} moreTitle={element.more_title} />}
            </div>
        </>
    );
}