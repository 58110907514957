import { connect } from 'react-redux';
import ModalComparisonGroup from './ModalComparisonGroup';
import actions from '../../store/actions';

const mapStateToProps = (state) => ({
  appSettings: state.app.settings,
  organization: state.benchmark.searchOrganization,
  allOrganization: state.benchmark.allOrganization,
  searchOrganizationData: state.benchmark.searchOrganizationData,
  settingsBenchmark: state.benchmark.benchmarkSettings,
  isSearchLoader: state.benchmark.isSearchLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getBenchmarkComparisonGroup: () => dispatch(actions.benchmark.getBenchmarkComparisonGroup()),
  searchByAllOrganizations: (params) => dispatch(actions.benchmark.searchByAllOrganizations(params)),
  getBenchmarkForms: () => dispatch(actions.benchmark.getBenchmarkForms()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalComparisonGroup);