import { connect } from 'react-redux';
import AnalyseList from './AnalyseList';
import actions from "../../../../store/actions";

const mapStateToProps = (state) => ({
  user: state.user,
  settingsBenchmark: state.benchmark.benchmarkSettings,
  forms: state.benchmark.benchmarkForms,
});

const mapDispatchToProps = (dispatch) => ({
  getBenchmarkPeriods: (params) => dispatch(actions.benchmark.getBenchmarkPeriods(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyseList);