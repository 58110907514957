import React, { useEffect, useMemo, useState } from "react";
import { Input, message, Select, Tooltip, Modal } from "antd";
import { useParams } from "react-router-dom";
import { ReportGrid } from "./../";
import { FavoritesGrid } from "./../";
import TabsHeader from "./../TabsHeader";

import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import QuestionCircleOutlined from "@ant-design/icons/lib/icons/QuestionCircleOutlined";
import BenchmarkSave from "../../../../assets/images/svg/benchmarkSave";
import { ModalSavedView } from "./../../modal";

import { showConfirm } from "./../ReportFilters/confirm";

const { confirm } = Modal;
const { Option } = Select;

export default function ReportFavorites({
  t,
  gridColumns,
  favoriteIndicators,
  settingsBenchmark,
  elementsData,
  active_organisation,
  period_start,
  period_end,
  calculation_types,
  comparison_groups,
  individual_organisations,
  handleDeleteFavorite
}) {

  return (
    <>
      <div className={"report__tabs"}>
        <div className={"tabs__header"}>
          {gridColumns?.map((item, index) => (
            <Tooltip
              title={item.Tooltip}
              placement={"top"}
              key={item.name + index}
            >
              <div className={"columns"} key={item.name + index}>
                {item.title}
              </div>
            </Tooltip>
          ))}
        </div>
        <div className={"report__analyse"}>
          <FavoritesGrid
            t={t}
            elementsData={elementsData}
            favoriteIndicators={favoriteIndicators}
            active_organisation={active_organisation}
            period_start={period_start}
            period_end={period_end}
            calculation_types={calculation_types}
            comparison_groups={comparison_groups}
            individual_organisations={individual_organisations}
            handleDeleteFavorite={handleDeleteFavorite}
          />
        </div>
      </div>
    </>
  );
}
