import { Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import React from "react";
import { withTranslation } from "react-i18next";

function Help({active, help, onClick, required, title}) {
    const style = {
        alignItems: "center",
        borderBottom: "none",
        color: "#000",
        display: "flex",
        fontSize: "18px",
        justifyContent: "flex-start"
    };

    if (active)
        style.color = "#1369EA";

    return (
        <>
            <legend className={required ? "required" : ""} onClick={onClick} style={style}>
                <div style={{alignItems: "top", display: "flex", justifyContent: "center"}}>
                    {title}
                </div>

                {help && (
                    <Popover content={<div dangerouslySetInnerHTML={{ __html: help }} />}>
                        <QuestionCircleOutlined style={{ cursor: "help", marginRight: 4 }}/>
                    </Popover>
                )}
            </legend>
        </>
    );
}

export default withTranslation('questionnaire')(Help);