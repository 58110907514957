import React, { useEffect, useState } from "react";
import { Collapse, Modal, Spin } from "antd";

import {
  PieChartOutlined,
  InfoCircleOutlined,
  BranchesOutlined,
} from "@ant-design/icons/lib/icons";
import BenchmarkMap from "../../../../assets/images/svg/benchmarkMap";
import { NoRecords } from "../../../../components";
import ModalCharts from "../../modal/ModalCharts";
import ModalDrillDown from "../../modal/ModalDrillDown";
import FavoritesGridRow from "./FavoritesGridRow";

const { Panel } = Collapse;

export default function FavoritesGrid({
  t,
  user,
  elementsData,
  favoriteIndicators,
  settingsBenchmark,
  active_organisation,
  period_start,
  period_end,
  calculation_types,
  comparison_groups,
  individual_organisations,
  getBenchmarkValuesForIndicator,
  handleDeleteFavorite
}) {
  favoriteIndicators = favoriteIndicators?.sort(function (a, b) {
    return a.indicator.localeCompare(b.indicator);
  });

  return (
    <>
      {favoriteIndicators ? (
        favoriteIndicators?.map((item, index) => (
          <FavoritesGridRow
            key={item.indicator}
            indicator={item.indicator}
            category={item.category}
            title={item.title}
            index={index}
            description={elementsData?.find(element => element.webform_indicator = item.indicator)?.description || ""}
            // favoritesData={favoritesData}
            t={t}
            user={user}
            settingsBenchmark={settingsBenchmark}
            active_organisation={active_organisation}
            period_start={period_start}
            period_end={period_end}
            calculation_types={calculation_types}
            comparison_groups={comparison_groups}
            individual_organisations={individual_organisations}
            handleDeleteFavorite={handleDeleteFavorite}
            getBenchmarkValuesForIndicator={getBenchmarkValuesForIndicator}
          />
        ))
      ) : (
        <NoRecords />
      )}
    </>
  );
}
