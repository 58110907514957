import React from "react";

import { Tabs } from 'antd';
import InfoCircleOutlined from "@ant-design/icons/lib/icons/InfoCircleOutlined";
import './ModalCharts.scss';
import {Charts, Kart} from "./components";

const { TabPane } = Tabs;

const ModalCharts = ({
  modalValue,
  modalCharts,
  indexCharts,
  showMap,
  startDate,
  endDate,
  t,
  mapFile,
  optionList,
  settingsBenchmark,
  defaultTabKey,
  organisationData,
  comparisonGroup,
  indicator,
  activeOrganization,
  drillDownGroups,
  modalVisible,
  decimalPlaces,
  periodType,
  descriptionText,
  mapOrgData
}) => {

  return (
    <div className={'modal__charts'} key={defaultTabKey}>
      <div className={'modal__indicators'}>
        {modalValue?.webform_indicator}
        <InfoCircleOutlined/>
      </div>

      <Tabs
        defaultActiveKey={defaultTabKey}
      >
        <TabPane tab={'Grafiek'} key={'charts'}>
          <Charts
            key={'charts'}
            modalCharts={modalCharts}
            modalValue={modalValue}
            indexCharts={indexCharts}
            startDate={startDate}
            endDate={endDate}
            activeOrganization={activeOrganization}
            settingsBenchmark={settingsBenchmark}
            t={t}
            organisationData={organisationData}
            comparisonGroup={comparisonGroup}
            indicator={indicator}
            modalVisible={modalVisible}
            periodType={periodType}
            descriptionText={descriptionText}
          />
        </TabPane>
        {showMap && (
          <TabPane tab={'Kaart'} key={'map'}>
            <Kart
              key={'map'}
              mapFile={mapFile}
              optionList={optionList}
              t={t}
              organisationData={organisationData}
              comparisonGroup={comparisonGroup}
              startDate={startDate}
              endDate={endDate}
              indicator={indicator}
              activeOrganization={activeOrganization}
              drillDownGroups={drillDownGroups}
              modalVisible={modalVisible}
              decimalPlaces={decimalPlaces}
              mapOrgData={mapOrgData}
            />
          </TabPane>
        )}
      </Tabs>

    </div>
  )
};

export default ModalCharts;
