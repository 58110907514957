import React from "react";
import './AnalyseList.scss';
import {withTranslation} from "react-i18next";
import ListInfo from "../ListInfo";
import history from "../../../../history";

const AnalyseList = ({ t, forms, handlerSetFormId, user: { isGuest } }) => {

  return (
    <div className={'analyse-block'}>
      <div className={'analyse-list'}>
        {forms?.map((item) => (
          item?.is_security && !isGuest &&
          <ListInfo
            key={item.id}
            title={item.title}
            actionFunction={handlerSetFormId}
            buttonText={t('view_questionnaire')}
            id={item.id}
          />

        ))}
      </div>
      <div className={'feedback'}>Feedback</div>
    </div>
  )
}

export default withTranslation('benchmark')(AnalyseList);
