export default {
  general: {
    all_questionnaires: 'All Questionnaires',
    all_reports: 'All Reports',
    apply: 'Apply',
    benchmark: 'Benchmark',
    cancel: 'Cancel',
    dashboard: 'Dashboard',
    edit_organisations: 'Edit Organisations',
    file_manager: 'File manager',
    file_overview: 'File overview',
    home: 'Home',
    latest_questionnaires: 'Latest Questionnaires',
    latest_reports: 'Latest Reports',
    logout: 'Log out',
    no: 'No',
    ok: 'Ok',
    organisations: 'Organisations',
    question: 'Question',
    questionnaire: 'Questionnaire',
    questionnaires: 'Questionnaires',
    questions: 'Questions',
    reports: 'Reports',
    show_more: 'Show more',
    users: 'Users',
    view_all_questionnaires: 'View all questionnaires',
    view_all_reports: 'View all reports',
    welcome_back: 'Welcome back',
    yes: 'Yes',
  },
  benchmark: {
    add_filter: 'Add filter',
    add_new_group: 'Create group',
    add_to_comparison_groups: 'Add to comparison group',
    apply: 'To apply',
    average: 'Average',
    beepal_comparison_group_name: 'Determine name of comparison group',
    cancel: 'Cancel',
    card: 'Map',
    category: 'Category',
    characteristic: 'Characteristic',
    chart: 'Chart',
    сlear_all: 'Clear all',
    сollapse_all_close: 'Uncollapse all',
    сollapse_all_open: 'Collapse all',
    come_back: 'Come back',
    compare_to: 'Compare to',
    comparison_group: 'Comparison group',
    comparison_groups: 'Comparison groups',
    create_comparison_group: 'Create comparison group',
    create_new_group: 'Create new comparison group',
    delete: 'Delete',
    delete_comparison_group: 'Group delete!',
    delete_modal_title: 'Do you want to delete these view?',
    delete_success: 'Delete success!',
    description: "Description",
    download: 'Download',
    drilldown: 'Drilldown',
    drilldown_organisation: 'Child organisation',
    edit_group: 'Edit group',
    exact_match: 'Exact match',
    expand_child_menu: 'Expand child menu',
    favorite_indicators: 'Favorite indicators',
    feature: 'Feature',
    filter: 'Filter',
    indicators: 'Indicators',
    individual_organizations_title: 'You can add up to 5 organizations',
    input_name: 'Input name',
    level: 'Level',
    maximum: 'maximum',
    median: 'Median',
    minimum: 'minimum',
    my_comparison_group: 'My comparison group',
    my_organisations: 'My organizations',
    name: 'Name',
    name_in_reports: 'Choose a clearly recognizable name for this comparison group. This name appears in reports.',
    name_of_view: 'Display name',
    not_comparison_group: 'Do not select a comparison group',
    number_of_values: 'Number of values',
    organization_search: 'Search organisations',
    organizations: 'organizations',
    other_organisations: 'Other organizations',
    percentile: 'Percentile',
    period: 'Period',
    question_group: 'Question group',
    questionnaire: 'Questionnaire',
    result: 'Result',
    save: 'Save',
    save_favorite: 'Save favorite',
    save_group: 'Save group',
    save_view: 'Save view',
    saved_comparison_groups: 'Saved comparison groups',
    saved_favorites: 'Saved favorites',
    saved_views: 'Saved views',
    search: 'Search...',
    search_in_questionnaire: 'Search in questionnaire',
    search_naar: 'Search...',
    search_result: 'Search result',
    select: 'Select',
    select_all: 'Select all',
    select_individual_organizations: 'Select individual organizations',
    selected: 'selected',
    show: 'Show',
    show_all: 'Everything',
    show_more_lists: 'Show more lists',
    subcategory: 'Subcategory',
    sum: 'sum',
    table_settings: 'Table Settings',
    to_show: 'To show',
    view_questionnaire: 'Start benchmark',
    view_saved_successfully: 'View saved successfully',
    warning_text: 'Possible double counting of results. Adjust the comparison group.\n' +
    'The following results are already part of the comparison group at a higher level',
    warning_title: 'Look out',
    warning_org_text_end: ' organizations. Statistics are shown in the table. If you still want to view individual scores: reduce the number of organizations in the comparison group, or choose Graph view.',
    warning_org_text_start: 'The comparison group consists of more than ',       
    your_organisation: 'Your organisation',
    your_value: 'Your value',        
  },
  error: {
    back_home: 'Home',
    back_to: ' or return to ',
    back_to_the: 'Return to ',
    prev_page: 'Previous page',
    return_text: 'Return',
    text: "You don't have permission to access this page. Please contact your Site Administrator(s) to request access.",
    title: 'Access denied',
  },
  errors: {
    field_is_invalid: 'This field is invalid'
  },
  file_manager: {
    action: 'Action',
    add_file: 'Add file',
    author: 'User',
    back_to_prev: 'Back to the previous page',
    choose_file: 'Choose file',
    download_all_files:'Download all files',
    edit: 'Edit',
    edit_file: 'Edit file',
    export_button: 'Upload',
    file: 'File',
    filename: 'Filename',
    history: 'History',
    input_search: 'Search for...',
    last_modified: 'Last modified',
    organization: 'Organization',
    rules: 'rules',
    save: 'Save',
    upload_date: 'Upload date',
    upload_file: 'Upload file',
  },
  header: {
    address: 'Address',
    back_to_list: 'Back to questionnaires list',
    country_code: 'Country code',
    email: 'E-mail',
    level: 'Level',
    logout: 'logout',
    modal_title: 'Organisation info',
    phone_number: 'Phone number',
    search: 'search',
    title: 'Title',
  },
  help_center: {
    any_questions_block: 'Questions? Visit out Helpcenter.',
    go_to_help_center: 'Go to Helpcenter',
  }, 
  individual_organisations: {
    apply: 'Apply',
    individual_organizations_title: 'Beschikbare organisaties',
    select_individual_organizations: 'Selecteer organisaties'
  },
  loader: {
    text: 'Update please wait...'
  },
  login: {
    enter_password: 'Enter your password',
    enter_username: 'Enter your username',
    forgot_password: 'Uw wachtwoord vergeten?',
    keep_me_logged_in: 'Keep me logged in',
    login: 'Log in',
    password: 'Password',
    please_login_to_continue: 'Please login to continue.',
    username: 'Username',
    welcome: 'Welcome.',    
  },
  no_records: {
    text: 'No results',
  },
  organisations: {
    action: 'Action',    
    create: 'Create',
    create_organisation: 'Create Organisation',
    edit: 'Edit',
    edit_organisation: 'Edit Organisation',
    organisations: 'Organisations',
    title: 'Title',
  },
  organisation_groups: {
    apply: 'Apply',
    comparison_groups: 'Comparisongroups',
    filter_applied: 'Filter applied',
    group_deleted: 'Group deleted',
    no_comparison_group: 'No comparisongroup',
    saved_comparison_groups: 'Saved comparisongroups'
  },
  questionnaire: {
    add_remark: 'Add Remark',
    add_users: 'Add users',
    all_questionnaires: 'Overview of all questionnaires',
    be_careful: '<p>Be careful! By editing data you will undo the previous submission.<br />New data will only be stored after submission of questionnaire.<br />Are you sure you want to proceed?</p>',
    changes_will_not_be_saved: 'Are you sure? Changes you have made will not be saved',
    choose_answer: 'Choose answer',
    close_categories: 'Close all categories',
    collapse_all: 'Collapse all',
    comment: 'Comment',
    comments: 'Comments',
    edit_remark: 'Edit Remark',
    error_message: 'Error',
    error_messages: 'Errors',
    error_save: 'You can not complete questionnaire while it has errors!',
    expand_all: 'Expand all',
    filter_by_tag: 'Filter by tag',
    filter_by_type: 'Filter by type',
    group: 'group',
    groups: 'groups',
    home: 'Home',
    incomplete_message: "Can't submit questionnaire while it's incomplete",
    index: 'Index',
    last_update_by: 'Last update by',
    more: 'more',
    multi_organizations: 'Organizations',
    next_section: 'Next Section',
    open_categories: 'Open all categories',
    organizations: 'Organizations',
    previous_section: 'Previous Section',
    reopen: 'Re-open',
    reply: 'Reply',
    save: 'Save',
    save_close: 'Save and close',
    save_success: 'Saved success!',
    show_categories: 'Show categories',
    single_questionnaire: 'Overview of questionnaires from {{org}}',
    start_filling: 'Start Filling',
    status: 'Status',
    sub_group: 'subgroup',
    sub_groups: 'subgroups',
    submit: 'Complete',
    users: 'users',
    users_list: 'Users list',
    view_commit_log: 'View commit log',
    view_indicatordata_log: 'View indicatordata log',
    view_remarks_log: 'View remarks log',
    warning_message: 'Warning',
    warning_messages: 'Warnings',
    webform_save_access_error: 'You have no access to save this webform',
    worth: 'Worth',
    write_a_comment: 'Write a comment',
  },
  reports: {
    all_reports: 'Overview of all reports',
    choose_file: 'Choose file',
    created_on: 'Created on',
    download_as: 'Download as',
    export_button: 'Upload',
    export_to_docx: 'Export to docx',
    select_group: 'Select group',
    select_period: 'Select period',
    single_report: 'Overview of reports from {{org}}'
  },
  statuses: {
    completed: 'Completed',
    in_progress: 'In Progress',
    open: 'Open',
    pending: 'Pending',
    processing: 'Processing',
    task_completed: 'Task completed',
  },
  user: {
    add_profile_picture: 'Add profile picture',
    add_user: 'Add user',
    apply: 'Apply',
    back_to_user_list: 'Back to user list',
    can_edit: 'Receive emails for news items',
    cancel: 'Cancel',
    change_password: 'Change password',
    characteristics: 'Characteristics',
    confirm: 'Confirm',
    confirm_password: 'Confirm new password',
    create_mew_password: 'Create new password',
    delete_user: 'Delete user',
    edit_profile: 'Edit your profile',
    email: 'E-mail',
    error_edit_personal: 'Something went wrong!',
    feedback: 'Feedback',
    filtered_by_roles: 'Filtered by roles',
    first_name: 'First name',
    function: 'Function',
    generate: 'To generate',
    generate_password: 'Generate password',
    infixes: 'Infixes',
    last_change_password: 'Password last changed on',
    last_name: 'Last name',
    last_visit: 'Last logged in on:',
    new_password: 'New password',
    no_users: 'No users',
    not_strong_password:
      'The minimum requirements for a password are:\n' +
      'at least a total of 12 characters\n' +
      'at least 1 lowercase letter\n' +
      'at least 1 uppercase letter\n' +
      'at least 1 numeric character\n' +
      'at least 1 special character',
    note_password: 'Note: the password will not be saved until you click `Save`',
    old_password: 'Old password',
    organizations: 'Organizations',
    password: 'Password',
    password_not_match: 'The two passwords that you entered do not match!',
    password_update: 'Password update successfully!',
    phone_number: 'Phone number',
    please_confirm_new_password: 'Please confirm your new password!',
    please_enter_new_password: 'Please input your new password!',
    please_enter_old_password: 'Please input your old password!',
    please_select: 'Please select',
    rights: 'Rights',
    role: 'Role',
    save: 'Save',
    search_text: 'Search...',
    send_news: 'Receive emails for news items',
    send_password_reset_email: 'Send password reset email (token method)',
    should_change_password_after_login: 'User must change password at next login',
    successfully_edit: 'was successfully updated!',
    user_name: 'User name',
    user_profile: 'User profile',
    warning_email: 'Please enter your email!',
    warning_first_name: 'Please enter your first name!',
    warning_last_name: 'Please enter your last name!',
    warning_password: 'Enter your password!',
    warning_user_name: 'Please enter your username!',
    wrong_old_password: 'Wrong old password!',
  },
  welcome_modal: {
    body: `<p>To start filling out the first questionnaire, it is necessary to first create a project for which you want to fill out the questionnaire.</p>
<p>To do this, click on the menu item "Edit projects" in the left sidebar and then click on the "Add" button in the top right corner.</p>
<p>Then fill in the project name and click on "Add".</p>
<p>The first project is then created and the questionnaires for this project are ready to be filled in.</p>
<p>Good luck!</p>`,
    title: 'Welcome to the PMK tool!',
  }
}
