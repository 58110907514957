import * as t from "../types";

import { api } from "../../services";

const getBenchmark = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    url: settings.calculation_url,
    ...data,
  };

  dispatch({
    type: t.BENCHMARK_FETCHING,
    payload: true,
  });

  return api.benchmark
    .getBenchmark(params)
    .then(({ data }) => {
      if (data.success) {
        dispatch({
          type: t.BENCHMARK_GET_DATA,
          payload: data.values,
        });
      }
      return data;
    })
    .finally(() => {
      dispatch({
        type: t.BENCHMARK_FETCHING,
        payload: false,
      });
    });
};

const getBenchmarkList = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;
  const params = {
    url: settings.calculation_url,
  };

  return api.benchmark.getBenchmarkList(params).then(({ data }) => {
    return data;
  });
};

const getBenchmarkFavorites = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;

  const params = {
    site_id: settings.site_id,
    url: settings.calculation_url,
    ...data,
  };

  return api.benchmark.getBenchmarkFavorites(params).then(({ data }) => {
    return data;
  });
};

const getBenchmarkFavoriteViews = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;

  const params = {
    site_id: settings.site_id,
    url: settings.calculation_url,
    ...data,
  };

  return api.benchmark.getBenchmarkFavoriteViews(params).then(({ data }) => {    
    data.values = data.values.map(x => {
      x.indicators = JSON.parse(x.indicators);
      return x;
    });
    return data;
  });
};

const setBenchmarkFavoriteViews = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;

  const params = {
    site_id: settings.site_id,
    url: settings.calculation_url,
    ...data,
  };

  return api.benchmark.setBenchmarkFavoriteViews(params).then(({ data }) => {
    return data;
  });
};

export function getBenchmarkValuesForIndicator(data) {
  return (dispatch, getFromStore) => {
    const settings = getFromStore().app.settings;

    const params = {
      site_id: settings.site_id,
      url: settings.calculation_url,
      ...data
    };

    return api.benchmark.getBenchmarkValuesForIndicator(params).then(({ data }) => {
      return data;
    });
  };
}


export function addBenchmarkFavorite(data) {
  return (dispatch, getFromStore) => {
    const settings = getFromStore().app.settings;

    const params = {
      site_id: settings.site_id,
      url: settings.calculation_url,
      ...data,
    };

    return api.benchmark.addBenchmarkFavorite(params).then(({ data }) => {
      return data;
    });
  };
}

export function removeBenchmarkFavorite(data) {
  return (dispatch, getFromStore) => {
    const settings = getFromStore().app.settings;

    const params = {
      site_id: settings.site_id,
      url: settings.calculation_url,
      ...data,
    };

    return api.benchmark.removeBenchmarkFavorite(params).then(({ data }) => {
      return data;
    });
  };
}

const getBenchmarkData = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    url: settings.calculation_url,
    ...data,
  };

  return api.benchmark.getBenchmarkData(params);
};

const getBenchmarkDataOrg = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    url: settings.calculation_url,
    ...data,
  };

  return api.benchmark.getBenchmarkDataOrg(params);
};

const getBenchmarkDataForIndicator = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    url: settings.calculation_url,
    ...data,
  };

  return api.benchmark.getBenchmarkDataForIndicator(params);
};

const getBenchmarkDataForCategory = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    url: settings.calculation_url,
    ...data,
  };
  return api.benchmark.getBenchmarkDataForCategory(params);
};

const getBenchmarkChartData = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    url: settings.calculation_url,
    ...data,
  };
  return api.benchmark.getBenchmarkChartData(params);
};

const searchBenchmarkData = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    url: settings.calculation_url,
    ...data,
  };

  return api.benchmark.searchBenchmarkData(params);
};

const getBenchmarkDrillDown = (data) => (dispatch, getFromStore) => {
  dispatch({
    type: t.BENCHMARK_FETCHING,
    payload: true,
  });

  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    format: "json",
    url: settings.calculation_url,
    ...data,
  };

  return api.benchmark
    .getBenchmarkDrillDown(params)
    .then((response) => {
      const { status, data } = response;
      if (status === 200) {
        dispatch({
          type: t.BENCHMARK_GET_MAP_DATA,
          payload: data.values,
        });
      }
      return response;
    })
    .finally(() => {
      dispatch({
        type: t.BENCHMARK_FETCHING,
        payload: false,
      });
    });
};

const getBenchmarkDrillDownForOrganizations = (data) => (dispatch, getFromStore) => {
  dispatch({
    type: t.BENCHMARK_FETCHING,
    payload: true,
  });

  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    format: "json",
    url: settings.calculation_url,
    ...data,
  };

  return api.benchmark
    .getBenchmarkDrillDownForOrganizations(params)
    .then((response) => {
      const { status, data } = response;
      if (status === 200) {
        dispatch({
          type: t.BENCHMARK_GET_MAP_DATA,
          payload: data.values,
        });
      }
      return response;
    })
    .finally(() => {
      dispatch({
        type: t.BENCHMARK_FETCHING,
        payload: false,
      });
    });
};

const getBenchmarkDrillDownForGroups = (data) => (dispatch, getFromStore) => {
  dispatch({
    type: t.BENCHMARK_FETCHING,
    payload: true,
  });

  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    format: "json",
    url: settings.calculation_url,
    ...data,
  };

  return api.benchmark
    .getBenchmarkDrillDownForGroups(params)
    .then((response) => {
      const { status, data } = response;
      if (status === 200) {
        dispatch({
          type: t.BENCHMARK_GET_MAP_DATA,
          payload: data.values,
        });
      }
      return response;
    })
    .finally(() => {
      dispatch({
        type: t.BENCHMARK_FETCHING,
        payload: false,
      });
    });
};

const getBenchmarkDrillDownForChildOrganizations = (data) => (dispatch, getFromStore) => {
  dispatch({
    type: t.BENCHMARK_FETCHING,
    payload: true,
  });

  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    format: "json",
    url: settings.calculation_url,
    ...data,
  };

  return api.benchmark
    .getBenchmarkDrillDownForChildOrganizations(params)
    .then((response) => {
      const { status, data } = response;
      if (status === 200) {
        dispatch({
          type: t.BENCHMARK_GET_MAP_DATA,
          payload: data.values,
        });
      }
      return response;
    })
    .finally(() => {
      dispatch({
        type: t.BENCHMARK_FETCHING,
        payload: false,
      });
    });
};

const getBenchmarkMap = (data) => (dispatch, getFromStore) => {
  dispatch({
    type: t.BENCHMARK_FETCHING,
    payload: true,
  });

  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    format: "json",
    url: settings.calculation_url,
    ...data,
  };

  return api.benchmark
    .getBenchmarkMap(params)
    .then((response) => {
      const { status, data } = response;
      if (status === 200) {
        dispatch({
          type: t.BENCHMARK_GET_MAP_DATA,
          payload: data.values,
        });
      }
      return response;
    })
    .finally(() => {
      dispatch({
        type: t.BENCHMARK_FETCHING,
        payload: false,
      });
    });
};

const getBenchmarkOrgDataForIndicator = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    url: settings.calculation_url,
    ...data,
  };

  return api.benchmark.getBenchmarkOrgDataForIndicator(params);
};

const getBenchmarkOrgDataForCategory = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    url: settings.calculation_url,
    ...data,
  };

  return api.benchmark.getBenchmarkOrgDataForCategory(params);
};

const getBenchmarkPeriods = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    url: settings.calculation_url,
    ...data,
  };

  return api.benchmark.getBenchmarkPeriods(params);
};

const getBenchmarkDataCategory = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    url: settings.calculation_url,
    ...data,
  };

  return api.benchmark.getBenchmarkDataCategory(params);
};

const getBenchmarkDataFromExpression = (data) => (dispatch, getFromStore) => {
  const settings = getFromStore().app.settings;
  const params = {
    site_id: settings.site_id,
    url: settings.calculation_url,
    ...data,
  };

  return api.benchmark.getBenchmarkDataFromExpression(params);
};

const getBenchmarkOrgDataFromExpression =
  (data) => (dispatch, getFromStore) => {
    const settings = getFromStore().app.settings;
    const params = {
      site_id: settings.site_id,
      url: settings.calculation_url,
      ...data,
    };

    return api.benchmark.getBenchmarkOrgDataFromExpression(params);
  };

const getBenchmarkSettings = () => (dispatch) => {
  dispatch({
    type: t.BENCHMARK_FETCHING,
    payload: true,
  });

  return api.benchmark
    .getBenchmarkSettings()
    .then((response) => {
      const { status, data } = response;

      if (status === 200) {
        dispatch({
          type: t.BENCHMARK_GET_SETTINGS,
          payload: data.settings,
        });
      }
      return response;
    })
    .finally(() => {
      dispatch({
        type: t.BENCHMARK_FETCHING,
        payload: false,
      });
    });
};

const getBenchmarkForms = () => (dispatch) => {
  dispatch({
    type: t.BENCHMARK_FETCHING,
    payload: true,
  });

  return api.questionnaires
    .getForms()
    .then((response) => {
      const { status, data } = response;

      if (status === 200) {
        dispatch({
          type: t.BENCHMARK_GET_FORMS,
          payload: data?.forms,
        });
      }
      return response;
    })
    .finally(() => {
      dispatch({
        type: t.BENCHMARK_FETCHING,
        payload: false,
      });
    });
};

const getBenchmarkComparisonGroups = () => (dispatch) => {
  dispatch({
    type: t.BENCHMARK_FETCHING,
    payload: true,
  });

  return api.benchmark
    .getComparisonGroups()
    .then((response) => {
      const { status, data } = response;

      if (status === 200) {
        dispatch({
          type: t.BENCHMARK_GET_COMPARISON_GROUP,
          payload: data?.comparison_groups,
        });
      }
      return response;
    })
    .finally(() => {
      dispatch({
        type: t.BENCHMARK_FETCHING,
        payload: false,
      });
    });
};

const getBenchmarkOrganization = () => (dispatch) => {
  dispatch({
    type: t.BENCHMARK_FETCHING,
    payload: true,
  });

  return api.organisations
    .getAllOrganization()
    .then((response) => {
      const { status, data } = response;

      if (status === 200) {
        dispatch({
          type: t.BENCHMARK_GET_ALL_ORGANIZATION,
          payload: data?.organisations,
        });
      }
      return response;
    })
    .finally(() => {
      dispatch({
        type: t.BENCHMARK_FETCHING,
        payload: false,
      });
    });
};

const createNewBenchmarkView = (data, id) => () => {
  return api.benchmark.createNewBenchmarkView(data, id);
};

const getBenchmarkViewList = (id) => (dispatch) => {
  dispatch({
    type: t.BENCHMARK_FETCHING,
    payload: true,
  });

  return api.benchmark
    .getBenchmarkViewList(id)
    .then((response) => {
      const {
        status,
        data: { benchmark_views },
      } = response;

      if (status === 200) {
        dispatch({
          type: t.BENCHMARK_GET_SAVED_VIEW,
          payload: benchmark_views,
        });
      }
    })
    .catch((e) => console.error(e))
    .finally(() => {
      dispatch({
        type: t.BENCHMARK_FETCHING,
        payload: false,
      });
    });
};

const getBenchmarkSpecialView = (id) => () => {
  return api.benchmark.getBenchmarkSpecialView(id);
};

const deleteBenchmarkSpecialView = (id) => () => {
  return api.benchmark.deleteBenchmarkSpecialView(id);
};

const searchByAllOrganizations = (params) => (dispatch) => {
  dispatch({ type: t.BENCHMARK_SEARCH_START });

  return api.benchmark
    .searchByAllOrganizations(params)
    .then((response) => {
      const { status, data } = response;

      if (status === 200) {
        dispatch({
          type: t.BENCHMARK_SEARCH_ORGANIZATION_DATA,
          payload: data?.organizations?.map((item) => {
            return {
              id: item?.origin_id,
              title: item?.title,
            };
          }),
        });
      }
    })
    .catch((e) => console.error(e))
    .finally(() => dispatch({ type: t.BENCHMARK_SEARCH_END }));
};

export {
  getBenchmark,
  getBenchmarkFavorites,
  getBenchmarkList,
  getBenchmarkData,
  getBenchmarkDataOrg,
  getBenchmarkDrillDown,
  getBenchmarkDrillDownForOrganizations,
  getBenchmarkDrillDownForGroups,
  getBenchmarkDrillDownForChildOrganizations,
  getBenchmarkDataCategory,
  getBenchmarkDataForIndicator,
  getBenchmarkDataFromExpression,
  getBenchmarkOrgDataFromExpression,
  getBenchmarkDataForCategory,
  getBenchmarkOrgDataForCategory,
  getBenchmarkPeriods,
  getBenchmarkOrgDataForIndicator,
  searchBenchmarkData,
  getBenchmarkSettings,
  getBenchmarkForms,
  getBenchmarkComparisonGroups,
  getBenchmarkOrganization,
  createNewBenchmarkView,
  getBenchmarkViewList,
  getBenchmarkSpecialView,
  deleteBenchmarkSpecialView,
  searchByAllOrganizations,
  getBenchmarkMap,
  getBenchmarkChartData,
  
  getBenchmarkFavoriteViews,
  setBenchmarkFavoriteViews,
};
