import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Checkbox, message, Modal, Popover, Radio, Tooltip } from "antd";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import MinusSquareOutlined from "@ant-design/icons/lib/icons/MinusSquareOutlined";
import BenchmarkTableEdit from "../../../../assets/images/svg/benchmarkTableEdit";
import PlusSquareOutlined from "@ant-design/icons/lib/icons/PlusSquareOutlined";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";

import "./FilterBlock.scss";
import { sortByField } from "../../utils";
import { ModalComparisonGroup } from "../../../../components";
import { api } from "../../../../services";

const MAXIMUM_SPECIAL_GROUPS = 5;

const FilterBlock = ({
  t,
  user,
  allOrganization,
  getBenchmarkOrganization,
  comparisonGroups,
  settingsBenchmark,
  optionList,
  checkedList,
  steps,
  handlerCheckedFilters,
  setComparisonGroupIds,
  getActiveOrganization,
  getBenchmarkComparisonGroups,
  handlerOpenAllCollapse,
  comparisonGroupIds,
  viewPage = false,
  generateReport,
  handlerSetSpecialOrganisations,
}) => {
  const { id } = useParams();

  const [activeOrganization, setActiveOrganization] = useState(null);
  const [indicatorFilterData, setIndicatorFilterData] = useState(null);
  const [defaultComparisonGroups, setDefaultComparisonGroups] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [organizationsInEditGroup, setOrganizationsInEditGroup] = useState(null);
  const [visibleOrganizationPopover, setVisibleOrganizationPopover] = useState(false);
  const [visibleGroupPopover, setVisibleGroupPopover] = useState(false);
  const [visibleIndividualGroupPopover, setVisibleIndividualGroupPopover] = useState(false);
  const [comparisonGroupEditId, setComparisonGroupEditId] = useState(null);
  const [comparisonGroupsChecked, setComparisonGroupsChecked] = useState([]);
  const [openAllCollapse, setOpenAllCollapse] = useState(false);
  const [showApplyButton, setShowApplyButton] = useState(false);
  const [specialGroupsId, setSpecialGroupsId] = useState([]);
  const [showApplyOrganizationButton, setShowApplyOrganizationButton] = useState(false);

  useEffect(() => {
    getBenchmarkOrganization();
  }, []);

  useEffect(() => {
    if (id) {
      api.questionnaires
        .getSegmentationElements(id)
        .then((response) => {
          const { status, data } = response;
          if (status === 200) {
            setIndicatorFilterData(data.webform);
          }
        })
        .catch((e) => console.error(e));
    }
  }, [id]);

  useEffect(() => {
    const defaultGroupNames = comparisonGroups.filter((item) => comparisonGroupIds.includes(item.id));

    setDefaultComparisonGroups(defaultGroupNames);
  }, [comparisonGroupIds, comparisonGroups]);

  const closeModal = () => {
    setModalVisible(false);
    setModalEdit(false);
    setOrganizationsInEditGroup(null);
  };

  useEffect(() => {
    const userActiveOrganisation = user?.data?.organisations.find(
      (item) => item.id === user?.data?.organisation
    );
    setActiveOrganization(userActiveOrganisation);
  }, []);

  const handlerChangeMyOrganization = (e) => {
    const organization = user?.data?.organisations.find(
      (item) => item.id === e.target.value
    );

    setShowApplyOrganizationButton(true);
    getActiveOrganization(organization);
    setActiveOrganization(organization);
  };

  const handlerSelectComparisonGroups = (ids) => {
    if (steps === 4)
      setShowApplyButton(true);

    setComparisonGroupsChecked(comparisonGroups.filter((item) => item.id in ids));
    setComparisonGroupIds(ids);
  };

  const organizations = user?.data?.organisations
    ?.sort(sortByField("title"))
    ?.map((item) => (
      <Radio value={item.id} key={item.id}>
        {item.title}
      </Radio>
    ));

  const contentPopoverOrganization = (
    <div className={"popover__organization"}>
      <div className={"radio__groups"}>
        <div className={"radio__group"}>
          <p className={"radio__title"}>{t("my_organisations")}</p>
          <Radio.Group
            value={activeOrganization?.id}
            onChange={handlerChangeMyOrganization}
          >
            {organizations}
          </Radio.Group>
          {steps === 4 && showApplyOrganizationButton && (
            <button
              className={"group__action apply__button organization__apply"}
              onClick={() => {
                setShowApplyOrganizationButton(false);
                setVisibleOrganizationPopover(false);
                generateReport();
              }}
            >
              {t("apply")}
            </button>
          )}
        </div>
        <div className={"radio__group"}>
          <p className={"radio__title"}>{t("other_organisations")}</p>
          <Radio.Group onChange={handlerChangeMyOrganization}>
            {Array.isArray(allOrganization)
              ? allOrganization?.sort(sortByField("title"))?.map((item) => (
                  <Radio value={item.id} key={item.id}>
                    {item.title}
                  </Radio>
                ))
              : allOrganization &&
                Object.values(allOrganization)
                  ?.sort(sortByField("name"))
                  ?.map((item) => (
                    <Radio value={item.id} key={item.id}>
                      {item.title}
                    </Radio>
                  ))}
          </Radio.Group>
        </div>
      </div>
    </div>
  );

  const deleteComparisonGroup = (id) => {
    api.benchmark
      .deleteComparisonGroup(id)
      .then((response) => {
        const { status } = response;

        if (status < 300)
          message.success(t("delete_comparison_group"));
      })
      .finally(() => {
        getBenchmarkComparisonGroups();

        setComparisonGroupsChecked(comparisonGroupsChecked.filter((item) => item.id != id));
        setDefaultComparisonGroups(defaultComparisonGroups.filter((item) => item.id != id));
        setComparisonGroupIds(comparisonGroupIds.filter((item) => item != id));

        setVisibleGroupPopover(false);
      });
  };

  const contentPopoverGroup = (
    <div className={"popover__group"}>
      <div className={"group__block"}>
        <div className={"group__item"}>
          <p className={"group__title"}>{t("saved_comparison_groups")}</p>
          <Checkbox.Group value={comparisonGroupIds} onChange={handlerSelectComparisonGroups}
          >
            {comparisonGroups?.map((item) => (
              <div className={"group__radio--item"} key={item.id}>
                <Checkbox key={item.id} value={item.id}>{item.name}</Checkbox>
                <div className={"group__icons"}>
                  {item.personal_group && (
                    <>
                      <EditOutlined
                        onClick={() => {
                          setModalVisible(true);
                          setModalEdit(item.name);
                          setOrganizationsInEditGroup(item.organizations);
                          setVisibleGroupPopover(false);
                          setComparisonGroupEditId(item.id);
                        }}
                      />
                      <div
                        className={"delete-group"}
                        onClick={() => deleteComparisonGroup(item.id)}
                      />
                    </>
                  )}
                </div>
              </div>
            ))}
          </Checkbox.Group>
        </div>
        <div className={"group__item"}>
          <p className={"group__title"}>{t("create_comparison_group")}</p>
          <button
            className={"group__action"}
            onClick={() => {
              setModalVisible(true);
              setModalEdit(false);
              setVisibleGroupPopover(false);
            }}
          >
            {t("add_new_group")}
          </button>
          {steps === 4 && showApplyButton && (
            <button
              className={"group__action apply__button"}
              onClick={() => {
                setShowApplyButton(false);
                setVisibleGroupPopover(false);
                generateReport();
              }}
            >
              {t("apply")}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  const contentPopoverFilter = (
    <div className={"popover__content"}>
      <p className={"popover__title"}>Show statistics</p>
      <Checkbox.Group
        options={optionList}
        value={checkedList}
        onChange={handlerCheckedFilters}
      />
    </div>
  );

  const handlerCheckedSpecialGroup = (e) => {
    const currentGroupsId = [...specialGroupsId];

    const hasSpecialGroup = currentGroupsId?.find(
      (item) => item === e.target.value
    );
    if (hasSpecialGroup) {
      const newArray = currentGroupsId.filter(
        (item) => item !== e.target.value
      );
      setSpecialGroupsId(newArray);
    } else {
      currentGroupsId.push(e.target.value);
      setSpecialGroupsId(currentGroupsId);
    }
  };

  const handlerApplySpecialOrganisation = () => {
    const specialOrganisations = [];
    const sortOutData = specialGroupsId?.forEach((item) =>
      allOrganization?.filter((value) => {
        if (value.id === item)
          specialOrganisations.push(value);
      })
    );
    setVisibleIndividualGroupPopover(false);
    handlerSetSpecialOrganisations(specialOrganisations);
    message.success(`Filter ${t("apply")}!`);
  };

  const sortOrganisations = () => {
    return allOrganization?.sort(sortByField("title"));
  };

  const contentPopoverSpecificOrganisation = (
    <div className={"popover__individual--group"}>
      <div className={"group__block"}>
        <p className={"group__title"}>
          {comparisonGroupsChecked.length && comparisonGroupsChecked.name || defaultComparisonGroups.length && defaultComparisonGroups.name}
        </p>
        <p className={"group__subtitle"}>
          {t("individual_organizations_title")}
        </p>
        <div className={"group__checkboxes"}>
          <Checkbox.Group value={specialGroupsId}>
            {sortOrganisations()?.map((item) => (
              <Tooltip key={item.id} placement="left" title={item.title} zIndex={20}>
                <Checkbox
                  onChange={handlerCheckedSpecialGroup}
                  value={item.id}
                  key={item.id}
                >
                  {item.title}
                </Checkbox>
              </Tooltip>
            ))}
          </Checkbox.Group>
        </div>

        <button
          className={"action__button"}
          onClick={handlerApplySpecialOrganisation}
        >
          {t("apply")}
        </button>
      </div>
    </div>
  );

  const handleVisibleChange = (visible) => {
    setVisibleGroupPopover(visible);
  };

  const handleVisibleIndividualGroup = (visible) => {
    setVisibleIndividualGroupPopover(visible);
  };

  const handleVisibleOrganizationChange = (visible) => {
    setVisibleOrganizationPopover(visible);
  };

  return (
    <div className={"report__organization"}>
      <Modal
        style={{ top: 0, maxWidth: "100vw" }}
        width={"100%"}
        footer={null}
        open={modalVisible}
        onCancel={closeModal}
        title={
          modalEdit ? `${t("edit_group")} ${modalEdit}` : t("create_new_group")
        }
      >
        <ModalComparisonGroup
          visible={modalVisible}
          modalEdit={modalEdit}
          closeModal={closeModal}
          comparisonGroupEditId={comparisonGroupEditId}
          indicatorFilterData={indicatorFilterData}
          organizationsInEditGroup={organizationsInEditGroup}
        />
      </Modal>
      {!viewPage && (
        <>
          <div className={"report__your-organization"}>
            <Popover
              placement="bottomLeft"
              content={contentPopoverOrganization}
              trigger={"click"}
              open={visibleOrganizationPopover}
              onOpenChange={handleVisibleOrganizationChange}
            >
              <div className={"report__organization--popover"}>
                <p>{activeOrganization?.title || t("your_organisation")}</p>
                <DownOutlined />
              </div>
            </Popover>
          </div>

          <div className={"report__organization-text"}>{t("compare_to")}</div>

          <div className={"report__your-organization"}>
            <Popover
              placement="bottomLeft"
              content={contentPopoverGroup}
              trigger={"click"}
              open={visibleGroupPopover}
              onOpenChange={handleVisibleChange}
            >
              <div className={"report__organization--popover"}>
                <p>
                  {comparisonGroupsChecked.length == 1 && comparisonGroupsChecked?.name ||
                    defaultComparisonGroups.length == 1 && defaultComparisonGroups?.name ||
                    t("comparison_groups")}
                </p>
                <DownOutlined />
              </div>
            </Popover>
          </div>

          <div className={"report__your-organization"}>
            <Popover
              placement="bottomLeft"
              content={contentPopoverSpecificOrganisation}
              trigger={"click"}
              className={"popover__specific--group"}
              open={visibleIndividualGroupPopover}
              onOpenChange={handleVisibleIndividualGroup}
            >
              <div className={"report__organization--popover"}>
                <p style={{ maxWidth: "300px" }}>
                  {t("select_individual_organizations")}
                </p>
                <DownOutlined />
              </div>
            </Popover>
          </div>
        </>
      )}

      <div className={"report__organization--filters"}>
        <div className={"filter-content"}>
          {steps === 4 &&
            (openAllCollapse ? (
              <div
                className={"filter-text"}
                onClick={() => {
                  setOpenAllCollapse(false);
                  handlerOpenAllCollapse(false);
                }}
              >
                <span>{t("сollapse_all_open")}</span>
                <MinusSquareOutlined />
              </div>
            ) : (
              <div
                className={"filter-text"}
                onClick={() => {
                  setOpenAllCollapse(true);
                  handlerOpenAllCollapse(true);
                }}
              >
                <span>{t("сollapse_all_close")}</span>
                <PlusSquareOutlined />
              </div>
            ))}
          <div className={"filter-popover"}>
            <Popover
              placement="bottom"
              content={contentPopoverFilter}
              trigger={"click"}
            >
              <div className={"filter-text"}>
                {t("table_settings")}
                <BenchmarkTableEdit />
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
