import { connect } from 'react-redux';
import FilterBlock from './FilterBlock';
import actions from '../../../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
  allOrganization: state.benchmark.allOrganization,
  settingsBenchmark: state.benchmark.benchmarkSettings,
  comparisonGroups: state.benchmark.comparisonGroups || [],
});

const mapDispatchToProps = (dispatch) => ({
  getBenchmarkOrganization: () => dispatch(actions.benchmark.getBenchmarkOrganization()),
  getBenchmarkComparisonGroups: () => dispatch(actions.benchmark.getBenchmarkComparisonGroups()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterBlock);