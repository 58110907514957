import React from "react";
import { Tooltip } from "antd";

const TabsHeader = ({ gridColumns }) => {
  return (
    <div className={"tabs__header"}>
      {gridColumns?.map((item, index) => (
        <Tooltip title={item.Tooltip} placement={"top"} key={item.name + index}>
          <div className={"columns"} key={item.name + index}>
            {item.title}
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default TabsHeader;
